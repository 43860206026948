import React, { lazy, Suspense } from 'react';  
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';  
import { AuthProvider, useAuth } from './context/AuthContext';  

const Login = lazy(() => import('./pages/Login'));  
const Register = lazy(() => import('./pages/Register'));  
const Dashboard = lazy(() => import('./pages/Dashboard'));  

const ErrorBoundary = lazy(() => import('./components/ErrorBoundary'));  

const PrivateRoute: React.FC<{children: React.ReactNode}> = ({ children }) => {  
  const { isAuthenticated } = useAuth();  
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;  
};  

const App: React.FC = () => {  
  return (  
    <AuthProvider>  
      <Router>  
        <Suspense fallback={<div>Loading...</div>}>  
          <ErrorBoundary>  
            <Routes>  
              <Route path="/login" element={<Login />} />  
              <Route path="/register" element={<Register />} />  
              <Route   
                path="/dashboard"   
                element={  
                  <PrivateRoute>  
                    <Dashboard />  
                  </PrivateRoute>  
                }   
              />  
              <Route path="/" element={<Navigate to="/login" />} />  
            </Routes>  
          </ErrorBoundary>  
        </Suspense>  
      </Router>  
    </AuthProvider>  
  );  
};  

export default App;